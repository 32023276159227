@use 'colors' as c;

.aboutus {
	margin: 0 1.5rem;
	font-size: 1.6rem;
	&__text-box {
		text-align: justify;
	}

	&__heading {
		margin: 1em 0;
		color: c.$orange;
	}

	&__img {
		margin-top: 1em;
		border-radius: 15px;
		width: 100%;

		&--video {
			height: 315px;
			width: 100%;
		}
	}
}

.offer {
	margin: 0 1.5em;
	&__box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		overflow: auto;
		gap: 3.5em;

		&-title {
			padding: 0 1em;
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 232px;
			height: 80px;
			text-align: center;
			font-size: 2rem;
			margin-bottom: 0.5em;

			&--orange {
				margin: 3.5em 0 1.5em;
				color: c.$orange;
			}
		}

		&-price {
			color: c.$orange;
			font-size: 2rem;
			text-align: center;
		}

		&-description {
			padding: 0 1.5em 1.5em 1.5em;
			color: c.$white;
			font-size: 2rem;
			text-align: justify;
			overflow: auto;
			font-size: 1.8rem;
			span {
				margin-top: 0.5rem;
				display: inline-block;
				font-weight: bold;
			}
		}
	}

	&__box-item {
		position: relative;
		padding: 4em 1.5em 1.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border: 1px solid c.$orange;
		border-radius: 15px;
		&-img {
			height: 300px;
			margin-top: 2.5em;
			margin-bottom: 2em;
			border-radius: 15px;
			&--horizontal {
				width: 70vw;
				object-fit: contain;
			}
		}

		&-img-container {
			position: relative;
		}

		&-img-unavailable {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			padding: 0.5em 0;
			background-color: c.$black-alpha-darker;
			text-align: center;
			font-size: 2.4rem;
		}

		&-text {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			background-color: c.$lighter-black;
			border-radius: 15px;
			text-align: center;
			clip-path: circle(0 at 100% 0);
			transition: clip-path 0.8s;

			&--active {
				clip-path: circle(150% at 100% 0);
			}
		}

		&-see-more {
			padding: 0.5em 1em;
			position: absolute;
			right: 5px;
			top: 10px;
			background: none;
			z-index: 1;
			img {
				height: 3rem;
				width: auto;
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 3px;
				left: 10%;
				width: 80%;
				height: 2px;
				background-color: c.$orange;
				transform-origin: center;
				transform: scaleX(0);
				transition: transform 0.3s;
			}
			&:hover.offer__box-item-see-more::after,
			&:focus.offer__box-item-see-more::after {
				transform: scaleX(1);
			}
		}
	}
}

.gallery {
	margin: 1.5em;
	&__box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__large-img {
		text-align: center;
		position: relative;
		margin-bottom: 3em;
		width: 100%;
		img {
			height: 65vh;
			width: 100%;
			object-fit: cover;
			object-position: center;
			border-radius: 15px;
		}
	}

	&__photos-thumbnails {
		max-height: 15vh;
		margin: 0 auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		img {
			margin: 1em;
			width: 100%;
			background-size: cover;
			background-position: center;
			border-radius: 8px;
			&:first-child {
				margin-left: 0em;
			}

			&:last-child {
				margin-right: 0em;
			}
		}
	}

	&__link {
		margin-top: 1em;
		font-size: 2.5rem;
		text-align: center;
		&-a {
			position: relative;
			color: c.$orange;
			&::after {
				content: '';
				position: absolute;
				bottom: -3px;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: c.$orange;
				transform-origin: center;
				transform: scaleX(0);
				transition: transform 0.3s;
			}
			&:hover.gallery__link-a::after,
			&:focus.gallery__link-a::after {
				transform: scaleX(1);
			}
		}
	}
}

.contact {
	&__items {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	&__item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin: 1.5em;
		img {
			align-self: start;
			margin-right: 2em;
		}
	}

	&__heading {
		font-size: 2.4rem;
	}

	&__info {
		font-size: 1.8rem;
	}

	&__map {
		margin-top: 2em;
	}
}

@media (min-width: 768px) {
	.offer {
		&__box {
			overflow: unset;
			justify-content: center;
			flex-wrap: wrap;
			&-title {
				font-size: 2.2rem;
				width: unset;
			}

			&-price {
				font-size: 2.3rem;
			}
		}
		&__box-item {
			&-img {
				height: 355px;
				&--horizontal {
					height: 355px;
					width: 400px;
				}
			}
		}
	}

	.gallery {
		&__large-img {
			&__large-img {
				height: 600px;
				width: auto;
			}
		}
	}
}
@media (min-width: 992px) {
	.aboutus {
		&__box {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2em;
		}

		&__img {
			border-radius: 20px;
		}
	}

	.offer {
		&__box {
			justify-content: center;

			&-title {
				font-size: 2.2rem;
			}

			&-price {
				font-size: 2.3rem;
			}
		}
	}

	.gallery {
		&__large-img {
			width: 80%;
		}

		&__photos-thumbnails {
			width: 80%;
			img {
				border-radius: 15px;
			}
		}
	}

	.contact {
		&__items {
			margin: 0 0 6em 0;
			justify-content: space-evenly;
			flex-direction: row;
		}

		&__item {
			width: unset;
			margin-bottom: 0;
			margin-inline: 1.5em;
		}
		&__heading {
			white-space: nowrap;
		}
	}
}

@media (min-width: 1400px) {
	.aboutus {
		font-size: 1.8rem;

		&__heading {
			font-size: 2.2rem;
		}
	}
}
