@use 'colors' as c;

.footer {
	position: relative;
	padding: 0.5em 1.5em;
	background-color: c.$footer-bg-color;
	font-size: 1.6rem;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__icon {
		position: relative;
		padding: 0 .5em;

		&::after {
			content: '';
			position: absolute;
			bottom: -3px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: c.$orange;
			transform-origin: center;
			transform: scaleX(0);
			transition: transform 0.3s;
		}
		&:hover.footer__icon::after,
		&:focus.footer__icon::after {
			transform: scaleX(1);
		}
	}
}
