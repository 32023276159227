@use 'colors' as c;

.main-gallery {
	margin: 15vh 1.5em 5em;
	&__items {
		display: flex;
		flex-direction: column;
		gap: 1.5em;
	}
	&__item {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 250px);
		gap: 1.5em;
	}
	&__img:nth-child(1) {
		grid-row: 1 / 3;
		grid-column: 1 / 2;
	}

	&__img:nth-child(2) {
		grid-row: 1 / 2;
		grid-column: 2 / 3;
	}

	&__img:nth-child(3) {
		grid-row: 2 / 3;
		grid-column: 2 / 3;
	}

	&__item:nth-child(even) &__img:nth-child(1) {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
	}
	&__item:nth-child(even) &__img:nth-child(2) {
		grid-row: 2 / 3;
		grid-column: 1 / 2;
	}
	&__item:nth-child(even) &__img:nth-child(3) {
		grid-row: 1 / 3;
		grid-column: 2 / 3;
	}

	&__img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 8px;
		cursor: pointer;
	}

	&__popup-shadow {
		display: none;
		justify-content: center;
		align-items: center;
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		height: 90vh;
		width: 100%;
		background-color: c.$black-alpha;
		margin-top: 10vh;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&__popup-img {
		max-height: 85vh;
		width: 100%;
		border-radius: 8px;
		cursor: zoom-in;
		transition: transform 0.3s;

		&--enlarged {
			cursor: zoom-out;
			transform: scale(1.6);
		}
	}

	&__button-box {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 60px;
		width: 60px;
		background: none;
		z-index: 100;
		&--left {
			left: 0em;
		}

		&--right {
			right: 0em;
		}
	}
	&__arrow-left,
	&__arrow-right {
		position: absolute;
		padding: 0.3em;
		background-color: c.$black-alpha-darker;
		border-radius: 50%;
	}
}

@media (min-width: 768px) {
	.main-gallery {
		&__arrow-left,
		&__arrow-right {
			padding: 0.6em;
		}
	}
}

@media (min-width: 992px) {
	.main-gallery {
		&__items {
			gap: 2.5em;
		}
		&__item {
			grid-template-rows: repeat(2, 300px);
			gap: 2.5em;
		}
	}
}

@media (min-width: 1200px) {
	.main-gallery {
		&__item {
			grid-template-rows: repeat(2, 350px);
		}
	}
}
