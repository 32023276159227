@use 'components';
@use 'colors' as c;
@use 'nav';
@use 'header';
@use 'main';
@use 'footer';
@use 'popup';
@use 'error404';
@use 'gallery';
@use 'news';

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	scroll-padding-top: 10vh;
}

a {
	text-decoration: none;
	color: c.$white;
}

button {
	border: none;
	cursor: pointer;
}

ul {
	list-style: none;
}

textarea {
	resize: vertical;
}

body {
	font-family: 'Ysabeau Office', sans-serif;
	background-color: c.$bg-color;
	color: c.$white;
}
