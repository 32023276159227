.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
}

.section-heading {
  margin: 1em;
  font-size: 3rem;
  text-align: center;
}

.sticky-body {
  overflow: hidden;
}

.sticky-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgb(17, 17, 17);
}
.nav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  width: 100%;
}
.nav__logo {
  font-size: 2.4rem;
  margin-left: 0.7em;
  color: rgb(180, 113, 58);
  font-family: "Ysabeau Office", sans-serif;
  font-weight: bold;
}
.nav__hamburger-box {
  display: block;
  position: relative;
  padding: 1.3em;
  background: none;
}
.nav__hamburger-box--active .nav__hamburger:nth-child(1) {
  transform: translateY(4px) rotate(135deg);
}
.nav__hamburger-box--active .nav__hamburger:nth-child(2) {
  opacity: 0;
}
.nav__hamburger-box--active .nav__hamburger:nth-child(3) {
  transform: translateY(-4px) rotate(-135deg);
}
.nav__hamburger {
  height: 4px;
  width: 40px;
  border-radius: 0.5rem;
  background-color: rgb(255, 255, 255);
  transition: opacity 0.3s, transform 0.3s;
}
.nav__hamburger:nth-child(1) {
  transform: translateY(-5px);
}
.nav__hamburger:nth-child(3) {
  transform: translateY(5px);
}
.nav__items {
  position: fixed;
  top: 10vh;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  background-color: rgb(26, 25, 25);
  width: 100%;
  height: 90vh;
  transform: translateX(100%);
  transition: transform 0.3s;
}
.nav__items--active {
  transform: translateX(0);
}
.nav__item {
  display: block;
  padding: 0.5em 2em;
  margin: 0.5em;
  width: 60%;
  color: rgb(255, 255, 255);
  text-align: center;
  transform: translateX(200%);
  transition: color 0.3s, transform 0.3s;
}
.nav__item--active {
  transform: translateX(0);
}
.nav__item:nth-child(2) {
  transition: color 0.3s, transform 0.3s 0.1s;
}
.nav__item:nth-child(3) {
  transition: color 0.3s, transform 0.3s 0.2s;
}
.nav__item:nth-child(4) {
  transition: color 0.3s, transform 0.3s 0.3s;
}
.nav__item:nth-child(5) {
  transition: color 0.3s, transform 0.3s 0.4s;
}
.nav__item:nth-child(6) {
  transition: color 0.3s, transform 0.3s 0.5s;
}
.nav__item:nth-child(7) {
  transition: color 0.3s, transform 0.3s 0.6s;
}
.nav__item:hover, .nav__item:focus {
  color: rgb(180, 113, 58);
  outline: none;
}
.nav__item--btn {
  margin-top: 0.5em;
  padding: 0.5em 1em;
  background-color: rgb(180, 113, 58);
  color: rgb(255, 255, 255);
  border-radius: 15px;
  border: 1px solid transparent;
  transition: background-color 0.3s, color 0.3s, border 0.3s, transform 0.3s 0.5s;
}
.nav__item--btn:hover, .nav__item--btn:focus {
  background-color: rgb(26, 25, 25);
  border: 1px solid rgb(180, 113, 58);
  outline: none;
}

@media (min-width: 576px) {
  .nav__item {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .nav__items {
    width: 50%;
    right: 0;
    transform: translateX(200%);
  }
  .nav__items--active {
    transform: translateX(100%);
  }
}
@media (min-width: 992px) {
  .nav__items {
    width: 45%;
    left: 10%;
  }
  .nav__item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .nav__items {
    width: 35%;
    left: 30%;
  }
  .nav__item {
    width: 70%;
  }
}
.header {
  margin-inline: 1.5em;
}
.header__box {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}
.header__text {
  text-align: center;
  width: 100%;
}
.header__text h1 {
  font-size: 4rem;
  margin-bottom: 0.2em;
}
.header__text p {
  font-size: 1.8rem;
  margin-bottom: 1em;
}
.header__btn {
  padding: 0.7em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-inline: auto;
  font-size: 1.8rem;
  background-color: rgb(180, 113, 58);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
  border-radius: 15px;
  width: 100%;
  transition: border 0.3s, background-color 0.3s;
}
.header__btn:hover, .header__btn:focus {
  outline: none;
  background-color: rgb(26, 25, 25);
  border: 1px solid rgb(180, 113, 58);
}
.header__img {
  width: 100%;
  text-align: center;
}
.header__back-img {
  background-image: url("../../dist/img/header_back.webp");
  width: 100%;
  height: 95vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
}
.header__front-img {
  display: none;
}

@media (min-width: 992px) {
  .header__box {
    margin-top: 10em;
    flex-direction: row;
    justify-content: space-between;
    height: 90vh;
  }
  .header__img {
    position: relative;
    width: 40%;
  }
  .header__text {
    display: grid;
    grid-template-rows: repeat(11, 1fr);
    width: 50%;
    height: 100%;
  }
  .header__text-items {
    grid-row: 6/7;
  }
  .header__back-img {
    background-position: center;
    background-attachment: unset;
    height: 80vh;
  }
  .header__front-img {
    display: inline;
    position: absolute;
    left: -15%;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    border-radius: 15px;
  }
  .header__btn {
    grid-row: 10/-2;
  }
}
.aboutus {
  margin: 0 1.5rem;
  font-size: 1.6rem;
}
.aboutus__text-box {
  text-align: justify;
}
.aboutus__heading {
  margin: 1em 0;
  color: rgb(180, 113, 58);
}
.aboutus__img {
  margin-top: 1em;
  border-radius: 15px;
  width: 100%;
}

.offer {
  margin: 0 1.5em;
}
.offer__box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  gap: 3.5em;
}
.offer__box-title {
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 232px;
  height: 80px;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0.5em;
}
.offer__box-title--orange {
  margin: 3.5em 0 1.5em;
  color: rgb(180, 113, 58);
}
.offer__box-price {
  color: rgb(180, 113, 58);
  font-size: 2rem;
  text-align: center;
}
.offer__box-description {
  padding: 0 1.5em 1.5em 1.5em;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  text-align: justify;
  overflow: auto;
  font-size: 1.8rem;
}
.offer__box-description span {
  margin-top: 0.5rem;
  display: inline-block;
  font-weight: bold;
}
.offer__box-item {
  position: relative;
  padding: 4em 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(180, 113, 58);
  border-radius: 15px;
  width: 73%;
}
.offer__box-item-img {
  height: 300px;
  margin-top: 2.5em;
  margin-bottom: 2em;
  border-radius: 15px;
}
.offer__box-item-img-container {
  position: relative;
}
.offer__box-item-img-unavailable {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5em 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 2.4rem;
}
.offer__box-item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(26, 25, 25);
  border-radius: 15px;
  text-align: center;
  clip-path: circle(0 at 100% 0);
  transition: clip-path 0.8s;
}
.offer__box-item-text--active {
  clip-path: circle(150% at 100% 0);
}
.offer__box-item-see-more {
  padding: 0.5em 1em;
  position: absolute;
  right: 5px;
  top: 10px;
  background: none;
  z-index: 1;
}
.offer__box-item-see-more img {
  height: 3rem;
  width: auto;
}
.offer__box-item-see-more::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 10%;
  width: 80%;
  height: 2px;
  background-color: rgb(180, 113, 58);
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.offer__box-item-see-more:hover.offer__box-item-see-more::after, .offer__box-item-see-more:focus.offer__box-item-see-more::after {
  transform: scaleX(1);
}

.winetasting {
  margin: 1.5em;
}
.winetasting__text-box {
  margin-bottom: 1.5em;
  font-size: 1.8rem;
  text-align: justify;
}

.gallery {
  margin: 1.5em;
}
.gallery__box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery__large-img {
  text-align: center;
  position: relative;
  margin-bottom: 3em;
  width: 100%;
}
.gallery__large-img img {
  height: 65vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
}
.gallery__photos-thumbnails {
  max-height: 15vh;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gallery__photos-thumbnails img {
  margin: 1em;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}
.gallery__photos-thumbnails img:first-child {
  margin-left: 0em;
}
.gallery__photos-thumbnails img:last-child {
  margin-right: 0em;
}
.gallery__link {
  margin-top: 1em;
  font-size: 2.5rem;
  text-align: center;
}
.gallery__link-a {
  position: relative;
  color: rgb(180, 113, 58);
}
.gallery__link-a::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(180, 113, 58);
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.gallery__link-a:hover.gallery__link-a::after, .gallery__link-a:focus.gallery__link-a::after {
  transform: scaleX(1);
}

.contact__items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.contact__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 1.5em;
}
.contact__item img {
  align-self: start;
  margin-right: 2em;
}
.contact__heading {
  font-size: 2.4rem;
}
.contact__info {
  font-size: 1.8rem;
}
.contact__map {
  margin-top: 2em;
}

@media (min-width: 768px) {
  .offer__box {
    overflow: unset;
    justify-content: center;
    flex-wrap: wrap;
  }
  .offer__box-title {
    font-size: 2.2rem;
    width: unset;
  }
  .offer__box-price {
    font-size: 2.3rem;
  }
  .offer__box-item {
    width: 300px;
  }
  .offer__box-item-img {
    height: 355px;
  }
  .gallery__large-img__large-img {
    height: 600px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .aboutus__box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
  }
  .aboutus__img {
    border-radius: 20px;
  }
  .offer__box {
    justify-content: center;
  }
  .offer__box-title {
    font-size: 2.2rem;
  }
  .offer__box-price {
    font-size: 2.3rem;
  }
  .gallery__large-img {
    width: 80%;
  }
  .gallery__photos-thumbnails {
    width: 80%;
  }
  .gallery__photos-thumbnails img {
    border-radius: 15px;
  }
  .contact__items {
    margin: 0 0 6em 0;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .contact__item {
    width: unset;
    margin-bottom: 0;
    margin-inline: 1.5em;
  }
  .contact__heading {
    white-space: nowrap;
  }
}
@media (min-width: 1400px) {
  .aboutus {
    font-size: 1.8rem;
  }
  .aboutus__heading {
    font-size: 2.2rem;
  }
}
.footer {
  position: relative;
  padding: 0.5em 1.5em;
  background-color: rgb(12, 12, 12);
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__icon {
  position: relative;
  padding: 0 0.5em;
}
.footer__icon::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(180, 113, 58);
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.footer__icon:hover.footer__icon::after, .footer__icon:focus.footer__icon::after {
  transform: scaleX(1);
}

.popup {
  padding: 3em 1.5em 5em;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  background-color: rgb(17, 17, 17);
}
.popup__shadow {
  display: block;
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
}
.popup p {
  font-size: 2rem;
}
.popup__btns {
  margin: 2em 0;
}
.popup__btn {
  padding: 1em 0;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  font-size: 1.8rem;
  width: calc(50% - 3em);
  border: 1px solid transparent;
  color: rgb(17, 17, 17);
  transition: color 0.3s, background-color 0.3s, border 0.3s;
}
.popup__btn:hover, .popup__btn:focus {
  background-color: rgb(26, 25, 25);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(180, 113, 58);
}
.popup__btn--orange {
  margin-right: 2em;
  background-color: rgb(180, 113, 58);
  color: rgb(255, 255, 255);
}
.popup__btn--orange:hover, .popup__btn--orange:focus {
  color: rgb(180, 113, 58);
}
.popup__msg {
  display: none;
  margin-top: 2em;
  color: rgb(180, 113, 58);
}
.popup__msg a {
  color: rgb(180, 113, 58);
  border-bottom: 2px solid rgb(180, 113, 58);
}

.error {
  margin: 15vh 0 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 3rem;
}
.error img {
  width: 50%;
  margin-bottom: 1em;
}
.error__heading {
  font-size: 5rem;
}
.error__link {
  color: rgb(180, 113, 58);
  border-bottom: 2px solid rgb(180, 113, 58);
}

@media (min-width: 768px) {
  .error {
    font-size: 4rem;
  }
}
.main-gallery {
  margin: 15vh 1.5em 5em;
}
.main-gallery__items {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.main-gallery__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 250px);
  gap: 1.5em;
}
.main-gallery__img:nth-child(1) {
  grid-row: 1/3;
  grid-column: 1/2;
}
.main-gallery__img:nth-child(2) {
  grid-row: 1/2;
  grid-column: 2/3;
}
.main-gallery__img:nth-child(3) {
  grid-row: 2/3;
  grid-column: 2/3;
}
.main-gallery__item:nth-child(even) .main-gallery__img:nth-child(1) {
  grid-row: 1/2;
  grid-column: 1/2;
}
.main-gallery__item:nth-child(even) .main-gallery__img:nth-child(2) {
  grid-row: 2/3;
  grid-column: 1/2;
}
.main-gallery__item:nth-child(even) .main-gallery__img:nth-child(3) {
  grid-row: 1/3;
  grid-column: 2/3;
}
.main-gallery__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}
.main-gallery__popup-shadow {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 90vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 10vh;
  opacity: 0;
  transition: opacity 0.3s;
}
.main-gallery__popup-img {
  max-height: 85vh;
  width: 100%;
  border-radius: 8px;
  cursor: zoom-in;
  transition: transform 0.3s;
}
.main-gallery__popup-img--enlarged {
  cursor: zoom-out;
  transform: scale(1.6);
}
.main-gallery__button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  background: none;
  z-index: 100;
}
.main-gallery__button-box--left {
  left: 0em;
}
.main-gallery__button-box--right {
  right: 0em;
}
.main-gallery__arrow-left, .main-gallery__arrow-right {
  position: absolute;
  padding: 0.3em;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .main-gallery__arrow-left, .main-gallery__arrow-right {
    padding: 0.6em;
  }
}
@media (min-width: 992px) {
  .main-gallery__items {
    gap: 2.5em;
  }
  .main-gallery__item {
    grid-template-rows: repeat(2, 300px);
    gap: 2.5em;
  }
}
@media (min-width: 1200px) {
  .main-gallery__item {
    grid-template-rows: repeat(2, 350px);
  }
}
.news {
  margin: 15vh 0 1.5em;
}
.news__items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}
.news__item {
  padding: 3em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  background-color: rgb(26, 25, 25);
}
.news__item-img {
  width: 100%;
  border-radius: 15px;
}
.news__item-date {
  font-size: 1.4rem;
  background-color: rgb(180, 113, 58);
  border-radius: 15px;
  padding: 0.5em;
  display: inline;
  align-self: flex-end;
  text-align: center;
}
.news__item-heading {
  font-size: 2.4rem;
  color: rgb(180, 113, 58);
  text-align: center;
}
.news__item-info {
  font-size: 1.8rem;
  text-align: justify;
}

@media (min-width: 992px) {
  .news__item {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    border-radius: 15px;
  }
  .news__item-img {
    grid-column: 1/span 3;
    grid-row: 1/span 6;
  }
  .news__item-heading {
    grid-column: 4/span 4;
    grid-row: 1/2;
    display: inline-flex;
  }
  .news__item-info {
    grid-column: 4/span 5;
    grid-row: 1/span 5;
  }
  .news__item-date {
    grid-column: 9/span 1;
    grid-row: 6/span 1;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 10vh;
}

a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

button {
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

textarea {
  resize: vertical;
}

body {
  font-family: "Ysabeau Office", sans-serif;
  background-color: rgb(17, 17, 17);
  color: rgb(255, 255, 255);
}