@use 'colors' as c;

.news {
	margin: 15vh 0 1.5em;

	&__items {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 2em;
	}

	&__item {
		padding: 3em 1.5em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1.5em;
		background-color: c.$lighter-black;
	}

	&__item-img {
		width: 100%;
		border-radius: 15px;
	}

	&__item-date {
		font-size: 1.4rem;
		background-color: c.$orange;
		border-radius: 15px;
		padding: 0.5em;
		display: inline;
		align-self: flex-end;
		text-align: center;
	}

	&__item-heading {
		font-size: 2.4rem;
		color: c.$orange;
		text-align: center;
	}

	&__item-info {
		font-size: 1.8rem;
		text-align: justify;
	}
}

@media (min-width: 992px) {
	.news {
		&__item {
			display: grid;
			grid-template-columns: repeat(9, 1fr);
			grid-template-rows: repeat(6, 1fr);
			border-radius: 15px;
		}

		&__item-img {
			grid-column: 1 / span 3;
			grid-row: 1 / span 6;
		}

		&__item-heading {
			grid-column: 4 / span 4;
			grid-row: 1 / 2;
			display: inline-flex;
		}

		&__item-info {
			grid-column: 4 / span 5;
			grid-row: 1 / span 5;
		}

		&__item-date {
			grid-column: 9 / span 1;
			grid-row: 6 / span 1;
		}
	}
}
