@use 'colors' as c;

.wrapper {
	margin: 0 auto;
	width: 100%;
	max-width: 1400px;
}

.section-heading {
	margin: 1em;
	font-size: 3rem;
	text-align: center;
}

.sticky-body {
	overflow: hidden;
}

.sticky-footer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
