@use 'colors' as c;

.nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background-color: c.$bg-color;
	&__links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 10vh;
		width: 100%;
	}

	&__logo {
		font-size: 2.4rem;
		margin-left: 0.7em;
		color: c.$orange;
		font-family: 'Ysabeau Office', sans-serif;
		font-weight: bold;
	}

	&__hamburger-box {
		display: block;
		position: relative;
		padding: 1.3em;
		background: none;

		&--active .nav__hamburger:nth-child(1) {
			transform: translateY(4px) rotate(135deg);
		}
		&--active .nav__hamburger:nth-child(2) {
			opacity: 0;
		}
		&--active .nav__hamburger:nth-child(3) {
			transform: translateY(-4px) rotate(-135deg);
		}
	}

	&__hamburger {
		height: 4px;
		width: 40px;
		border-radius: 0.5rem;
		background-color: c.$white;
		transition: opacity 0.3s, transform 0.3s;

		&:nth-child(1) {
			transform: translateY(-5px);
		}
		&:nth-child(3) {
			transform: translateY(5px);
		}
	}

	&__items {
		position: fixed;
		top: 10vh;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		background-color: c.$lighter-black;
		width: 100%;
		height: 90vh;
		transform: translateX(100%);
		transition: transform 0.3s;
		&--active {
			transform: translateX(0);
		}
	}

	&__item {
		display: block;
		padding: 0.5em 2em;
		margin: 0.5em;
		width: 60%;
		color: c.$white;
		text-align: center;
		transform: translateX(200%);
		transition: color 0.3s, transform 0.3s;

		&--active {
			transform: translateX(0);
		}

		&:nth-child(2) {
			transition: color 0.3s, transform 0.3s 0.1s;
		}
		&:nth-child(3) {
			transition: color 0.3s, transform 0.3s 0.2s;
		}
		&:nth-child(4) {
			transition: color 0.3s, transform 0.3s 0.3s;
		}
		&:nth-child(5) {
			transition: color 0.3s, transform 0.3s 0.4s;
		}
		&:nth-child(6) {
			transition: color 0.3s, transform 0.3s 0.5s;
		}
		&:nth-child(7) {
			transition: color 0.3s, transform 0.3s 0.6s;
		}

		&:hover,
		&:focus {
			color: c.$orange;
			outline: none;
		}

		&--btn {
			margin-top: 0.5em;
			padding: 0.5em 1em;
			background-color: c.$orange;
			color: c.$white;
			border-radius: 15px;
			border: 1px solid transparent;
			transition: background-color 0.3s, color 0.3s, border 0.3s,
				transform 0.3s 0.5s;

			&:hover,
			&:focus {
				background-color: c.$lighter-black;
				border: 1px solid c.$orange;
				outline: none;
			}
		}
	}
}

@media (min-width: 576px) {
	.nav {
		&__item {
			width: 50%;
		}
	}
}

@media (min-width: 768px) {
	.nav {
		&__items {
			width: 50%;
			right: 0;
			transform: translateX(200%);
			&--active {
				transform: translateX(100%);
			}
		}
	}
}

@media (min-width: 992px) {
	.nav {
		&__items {
			width: 45%;
			left: 10%;
		}

		&__item {
			width: 50%;
		}
	}
}

@media (min-width: 1200px) {
	.nav {
		&__items {
			width: 35%;
			left: 30%;
		}

		&__item {
			width: 70%;
		}
	}
}
